<template>
    <v-btn
        color="primary"
        small
        depressed
        :href="downloadUrl"
        target="_blank"
        v-text="'Download'"
    />
</template>

<script>
export default {
    name: "DownloadAction",
    props: {
        downloadUrl: {
            type: String,
            required: true
        }        
    }
}
</script>
